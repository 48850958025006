import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
import { AutoplayOptions, PaginationOptions, SwiperOptions } from 'swiper/types';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { forwardRef } from 'react';

interface Props extends Omit<React.HTMLAttributes<HTMLElement>, 'children'>
{
  children: JSX.Element[];
  useBreakpoints?: boolean;
  loop?: boolean;
  slidesPerView?: number | 'auto';
  previewNextSlide?: number;
  spaceBetween?: number;
  className?: string;
  swiperSlideClassName?: string;
  fadeEffect?: boolean;
  showControls?: boolean;
  grabCursor?: boolean;
  autoplay?: boolean | AutoplayOptions;
  prevElement?: JSX.Element;
  nextElement?: JSX.Element;
  defaultIconColor?: string;
  defaultIconSize?: number;
  touchEnabled?: boolean;
  speed?: number;
  centeredSlides?: boolean;
  initialSlide?: number;
}

export const Carousel = forwardRef<SwiperRef, Props>(
  (props, ref) =>
  {
    const {
      useBreakpoints,
      children = [],
      slidesPerView = 'auto',
      previewNextSlide = 0,
      spaceBetween = 10,
      className,
      swiperSlideClassName,
      loop,
      fadeEffect,
      autoplay,
      showControls,
      prevElement,
      nextElement,
      defaultIconColor = 'white',
      defaultIconSize = 80,
      grabCursor,
      touchEnabled,
      speed,
      centeredSlides = false,
      initialSlide = 0
    } = props;

    const breakpoints = getBreakpoints(
      useBreakpoints || false,
      slidesPerView,
      previewNextSlide,
    );

    const navigation = showControls
      ? {
        prevEl: '.prev',
        nextEl: '.next',
      }
      : false;

    return (
      <Swiper
        ref={ ref }
        slidesPerView={ useBreakpoints ? undefined : slidesPerView }
        breakpoints={ breakpoints }
        spaceBetween={ spaceBetween }
        navigation={ navigation }
        className={ className }
        autoplay={ autoplay && (typeof autoplay === "boolean" ? {
          delay: 4000,
          disableOnInteraction: false
        } : autoplay) }
        loop={ loop }
        effect={ fadeEffect ? 'fade' : 'slide' }
        grabCursor={ grabCursor }
        allowTouchMove={ touchEnabled }
        allowSlideNext={ touchEnabled }
        allowSlidePrev={ touchEnabled }
        centeredSlides={ centeredSlides }
        speed={ speed }
        initialSlide={ initialSlide }
        modules={ [Navigation, Pagination, EffectFade, Autoplay] }
      >
        { children.map((swiperContent) => (
          <SwiperSlide key={ swiperContent.key } className={ swiperSlideClassName }>
            { swiperContent }
          </SwiperSlide>
        )) }
        { showControls && (
          <>
            <div className="prev group absolute top-1/2 transform -translate-y-1/2 left-0 z-50">
              { prevElement || (
                <ArrowLeft
                  size={ defaultIconSize }
                  color={ defaultIconColor }
                  className="pl-3 group-[.swiper-button-disabled]:opacity-50 cursor-pointer group-[.swiper-button-disabled]:cursor-auto"
                />
              ) }
            </div>
            <div className="next group absolute top-1/2 transform -translate-y-1/2 right-0 z-50">
              { nextElement || (
                <ArrowRight
                  size={ defaultIconSize }
                  color={ defaultIconColor }
                  className="pr-3 group-[.swiper-button-disabled]:opacity-50 cursor-pointer group-[.swiper-button-disabled]:cursor-auto"
                />
              ) }
            </div>
          </>
        ) }
      </Swiper>
    );
  });

const getBreakpoints = (
  useBreakpoints: boolean,
  slidesPerView: any,
  previewNextSlide: number,
):
  {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  } =>
{
  return useBreakpoints
    ? slidesPerView === 'auto' || slidesPerView === 4
      ? {
        320: {
          slidesPerView: 1 + previewNextSlide,
        },
        512: {
          slidesPerView: 2 + previewNextSlide,
        },
        768: {
          slidesPerView: 2 + previewNextSlide,
        },
        1024: {
          slidesPerView: 3 + previewNextSlide,
        },
        1280: {
          slidesPerView: 4 + previewNextSlide,
        },
      }
      : slidesPerView === 3
        ? {
          320: {
            slidesPerView: 1 + previewNextSlide,
          },
          512: {
            slidesPerView: 1 + previewNextSlide,
          },
          768: {
            slidesPerView: 2 + previewNextSlide,
          },
          1024: {
            slidesPerView: 2 + previewNextSlide,
          },
          1280: {
            slidesPerView: 3 + previewNextSlide,
          },
        }
        : slidesPerView === 2
          ? {
            320: {
              slidesPerView: 1 + previewNextSlide,
            },
            512: {
              slidesPerView: 1 + previewNextSlide,
            },
            768: {
              slidesPerView: 1 + previewNextSlide,
            },
            1024: {
              slidesPerView: 1 + previewNextSlide,
            },
            1280: {
              slidesPerView: 2 + previewNextSlide,
            },
          }
          : {}
    : {};
};
